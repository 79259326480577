.consultantOverview {

  &__name, &__label {
    padding-right: 0.5rem;
  }

  &__end-date {
    font-weight: 600;
  }


}