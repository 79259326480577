.dayPicker {
  &__button {
    border: none;
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-content: center;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
  }

  &__input-display {
    width: 100%;
  }

  &__image {
    width: 2rem;
    height: 2rem;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
  }
}

.rdp-day_selected:not(.rdp-day_selected.rdp-day_range_start):not(.rdp-day_selected.rdp-day_range_end) {
  background-color: rgba(153,255,213,1) !important;
  color: #4a90e2;
  border-radius: 0 !important;
}

.rdp-day_selected.rdp-day_range_start {
  background-image:  linear-gradient(to right, rgba(51,255,172,1), rgba(153,255,213,1));
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  color: #4a90e2;
  border-right: none;
  border-left: none;

}

.rdp-day_selected.rdp-day_range_end {
  background-image:  linear-gradient(to right, rgba(153,255,213,1), rgba(51,255,172,1));
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  color: #4a90e2;
  border-right: none;
  border-left: none;

}
