
html {
  font-size: 12px
}

:root, [data-theme] {
  --cat-drawer-background-color: var(--cds-background-color-inverted-light-opaque);

  --cat-header-height: 5rem; /* 60 px */

  --cat-homepage-background-color: var(--cds-background-color);

  --cat-homepanel-background-color: var(--cds-background-color-inverted-light-opaque);
  --cat-homepanel-element-background-color: var(--cds-background-color);
  --cat-homepanel-element-background-color-important: var(--cds-background-color-feedback-error);
  --cat-homepanel-element-border-color: var(--cds-border-color);
  --cat-homepanel-element-border-color-important: var(--cds-border-color-feedback-error);

  --cat-skill-color-background: var(--cds-background-color-alternative-light-opaque);
  --cat-skill-color-border: var(--cds-border-color);
  --cat-skill-color-text: var(--cds-text-color);
  --cat-skill-color-years-background: var(--cds-background-color);
  --cat-skill-color-proficiency-background: var(--cds-background-color);

  --cat-card-background: var(--cds-background-color);
  --cat-card-background-added: var(--cds-background-color-feedback-success);
  --cat-card-background-edited: var(--cds-background-color-feedback-warning);

  --cat-news-important-border-color: var(--cds-color-cegallime);
  --cat-news-important-icon-text-color: var(--cds-color-cegalgrey-darker80);

  --cat-chat-response-background: var(--cds-background-color-inverted-light-opaque);
  --cat-chat-sent-background: var(--cds-color-darkblue1-lighter20);
  --cat-chat-sent-color-text: var(--cds-color-cegalblack-lighter95);

  --cat-summarize-background: var(--cds-color-cegalgrey-lighter90)

}


[data-theme='cegal-dark'] {

  --cat-homepage-background-color: var(--cds-color-darkblue3);
  --cat-homepanel-element-background-color: var(--cds-color-darkblue3);
  --cat-card-background: var(--cds-color-darkblue3);

  --cat-chat-sent-background: var(--cds-background-color-inverted-light-opaque);
  --cat-chat-response-background: var(--cds-color-darkblue3-lighter20);

  --cat-news-important-border-color: var(--cds-color-cegallime-darker60);
  --cat-news-important-icon-text-color: var(--cds-color-cegallime);

  --cat-summarize-background: var(--cds-color-white-opacity5)
}

.transition .text {
  cursor: pointer;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: text-indent 7s linear;
}

.transition:hover .text {
  text-indent: -1000px;
}