.consultantChart {
  &__wrapper {
    display: flex;
    justify-content: center;
    align-content: center;
  }

  &__label {
    padding-top: 0.75rem;
    padding-right: 0.125rem;
  }

  &__consultant-chart {
    max-width: 100%;
  }

}
